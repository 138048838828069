import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'My Portfolio | Ezekiel David', 
  lang: 'en',
  description: '',
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Ezekiel David',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'I am a web developer from Manila, Philippines finished with a Bachelor of Science in Information Technology as a Presidents Lister.',
  paragraphTwo: ' I enjoy building everything from small business sites to rich interactive web apps.',
  paragraphThree: 'If you are a business seeking a web presence or an employer looking to hire, you can get in touch with me here',
  resume: 'https://drive.google.com/file/d/190o7LLHtX-cvhN1QV8JRWBT2N3UY2aKX/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'ishop.jpg',
    title: 'iShop',
    info: 'Elevate your online shopping experience with I-Shop PH! From same-day delivery to secure payments and personalized recommendations, our platform brings together the best of Filipino sellers and global products in one seamless app. Join us today and discover the future of convenient, community-driven shopping!',
    info2: 'Tech Used: React JS, React Native, NodeJS, TypeSense, Firebase, Typescript',
    url: 'https://play.google.com/store/apps/details?id=com.ishop.imove'
  },
  {
    id: nanoid(),
    img: 'staffwerk.png',
    title: 'StaffWerk',
    info: 'StaffWerk is not just an app; it’s a community where positivity, inclusivity, and flexibility are the cornerstones of our work environment. We are renowned for providing exceptional job opportunities and fostering a culture of mutual respect, collaboration, and open communication. Our rapid growth is creating new job openings and allowing us to expand into new markets, offering a diverse range of positions.',
    info2: 'Tech Used: React JS, ViteJS, TailwindCSS'
  },
  {
    id: nanoid(),
    img: 'traction.png',
    title: 'Traction Ag',
    info: ' Traction farm management software brings together your farm accounting, payroll, field operations and agronomy into one easy-to-use platform.',
    info2: 'Tech Used: React JS, GraphQL, Open Street Maps, PostgreSQL, Typescript',
    url: 'https://www.tractionag.com/'
  },
  {
    id: nanoid(),
    img: 'trackme.webp',
    title: 'trackMeFleet',
    info: 'trackMe Fleet is a web-based fleet management system built on a single integrated platform designed to assist you in your entire fleet operations, from comprehensive vehicle scheduling to monitoring driver performance.',
    info2: 'Tech Used: cakePHP, JQuery, Materialize CSS, MySQL',
    url: 'https://trackme.com.ph/',
    repo: '', // if no repo, the button will not show up
  },  
  {
    id: nanoid(),
    img: 'foneapi.png',
    title: 'txtMeQuick(FoneAPI)',
    info: 'txtMeQuick(FoneAPI) is an Enhanced Messaging platform that will allow you to interact with your customers in a format they prefer. You will be able to send and receive text or photos, auto-translate messages, store contacts, inform customers, automate replies, create group texts and much more.',
    info2: 'Tech Used: Meteor JS, React JS, Monggo DB, Web RTC, Twilio, Nexmo and OTP SMS APIs ',
    url: 'https://foneapi.com/product',
    repo: 'https://foneapi.com/', // if no repo, the button will not show up
  }
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'ezekielc.david14@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/ezekiel-david-252101165/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://www.github.com/EzekielDavid',
    },    {
      id: nanoid(),
      name: 'gitlab',
      url: 'https://gitlab.com/ec.david',
    }
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false,
};
